import React from "react"
import styled from "styled-components"

const TileLayout = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

const StyledTileLayout = ({ children }) => {
  return <TileLayout>{children}</TileLayout>
}

export default StyledTileLayout
