import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import {motion} from 'framer-motion'

const RecipeCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 250px;
  border: 2px solid #e6e6e6;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  vertical-align: bottom;
  position: relative;
  background-color: #fff;
  font-family: roboto;

  & a {
    height: 100%;
    text-decoration: none;
    color: #3486eb;
  }
`

const Name = styled.div`
  display: block;
`

const FlexLayout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`

const item = {
  visible: { opacity: 1, scale: 1, boxShadow: "2px 2px 0 rgba(0, 0, 0, 0.2)", },
  hidden: { opacity: 0, scale: 0 },
}

const RecipeCardComponent = ({ image, name, path }) => {
  return (
    <RecipeCard initial="hidden" animate="visible" variants={item} whileHover={{ scale: 1.1 }}>
      <Link to={path}>
        <FlexLayout>
          {image && <Img fluid={image} />}
          <Name>{name}</Name>
        </FlexLayout>
      </Link>
    </RecipeCard>
  )
}

export default RecipeCardComponent
