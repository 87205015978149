import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RecipeCard from "../components/RecipeCard"
import TileLayout from "../components/TileLayout"
// import { useStaticQuery } from "gatsby"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
    placeholderImage
  },
}) => {
  return (
    <React.Fragment>
      <Layout>
        <SEO title="Home" />
        <TileLayout>
          {edges.map(edge => {
            const {
              node: {
                frontmatter: { featuredImage, path, title },
              },
            } = edge
            let featuredImgFluid =
              featuredImage?.childImageSharp?.fluid ??
              placeholderImage?.childImageSharp?.fluid
            return (
              <RecipeCard
                image={featuredImgFluid}
                key={edge.id}
                name={title}
                path={path}
              />
            )
          })}
        </TileLayout>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage

// TODO: use the react hooks version of this
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "stock-photo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
